@font-face {
  font-family: "Cadiz";
  src: url("./assets/fonts/Cadiz-Book.woff2") format("woff2"),
    url("./assets/fonts/Cadiz-Book.woff") format("woff"),
    url("./assets/fonts/Cadiz-Book.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Cadiz";
  src: url("./assets/fonts/Cadiz-BookItalic.woff2") format("woff2"),
    url("./assets/fonts/Cadiz-BookItalic.woff") format("woff"),
    url("./assets/fonts/Cadiz-BookItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Cadiz";
  src: url("./assets/fonts/Cadiz-SemiBd.woff2") format("woff2"),
    url("./assets/fonts/Cadiz-SemiBd.woff") format("woff"),
    url("./assets/fonts/Cadiz-SemiBd.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Cadiz";
  src: url("./assets/fonts/Cadiz-SemiBdItalic.woff2") format("woff2"),
    url("./assets/fonts/Cadiz-SemiBdItalic.woff") format("woff"),
    url("./assets/fonts/Cadiz-SemiBdItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 500;
}

body {
  margin: 0;
  font-family: "Cadiz";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  font-size: 20px;
  background: url(./assets/img/bg-footer.jpg) right bottom no-repeat;
  background-size: 100% auto, 100% auto;
  background-color: #e8f0fa;
}
body.bg-orange {
  background-color: #fce9d8;
  background: url(./assets/img/bg-orange.jpg) right top no-repeat;
  background-size: 100% auto, 100% auto;
}

@media screen and (max-width: 670px) {
  body.bg-orange {
    background-size: cover;
  }
}

body.prevent-copypaste {
  user-select: none;
}
body.prevent-image-download img {
  pointer-events: none;
}
body.lock-scroll {
  overflow: hidden;
}
@media print {
  body.prevent-printing {
    visibility: hidden;
    display: none;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}
p {
  margin: 0;
}

label,
input,
button,
select,
textarea {
  font: 16px "Cadiz";
}

button {
  font-size: 16px;
}

h1 {
  font-size: 50px;
  margin: 0;
}

.item-enter {
  opacity: 0;
  top: -15px;
}
.item-enter-active {
  opacity: 1;
  top: 0;
  transition: opacity 400ms ease-in-out, top 400ms ease-in-out;
}
.item-exit {
  opacity: 1;
  top: 0;
}
.item-exit-active {
  opacity: 0;
  top: 15px;
  transition: opacity 400ms ease-in-out, top 400ms ease-in-out;
}

button {
  appearance: none;
  color: black;
}
